<template>
  <div :style="nodeMargin">
    <v-icon
      v-if="hasChildren && !showChildren"
      @click="showChildren = !showChildren"
      @keypress="showChildren = !showChildren"
      class="child-icon"
      aria-hidden="false"
      aria-text="Show Children"
    >
      mdi-chevron-down
    </v-icon>
    <v-icon
      v-if="hasChildren && showChildren"
      @click="showChildren = !showChildren"
      @keypress="showChildren = !showChildren"
      class="child-icon"
      aria-hidden="false"
      aria-text="Hide Children"
    >
      mdi-chevron-up
    </v-icon>
    <v-icon
      v-if="!hasChildren && arrayItem.part"
      v-show="hasFlowResults"
      @click="routeToRelatedBufferFlow()"
      class="child-icon"
      aria-hidden="false"
      aria-text="In route to related buffer"
    >
      mdi-tray-arrow-down
    </v-icon>
    <AccordionRowsRow
      v-if="arrayItem.part"
      :row="row"
      :entities="entities"
      :arrayItem="arrayItem"
      :isPending="isPending"
    />
    <div v-if="hasChildren" v-show="showChildren">
      <TreeNode
        v-for="child in get(arrayItem, 'children')"
        :key="child.id"
        :row="row"
        :entities="entities"
        :arrayItem="child"
        :spacing="spacing + 5"
      />
    </div>
  </div>
</template>
<script>
import AccordionRowsRow from "./AccordionRowsRow.vue";
import { get } from "lodash-es";
import { computed } from "vue-demi";
import { useFlowDetails } from "../../store/flowDetails.pinia";
import { computedRef } from "../../utils/composables";
import { useFind } from "../../utils/feathers-pinia/lib";
import { useRouter } from "../../utils/useRouter";
import { useToast } from "vue-toastification/composition";

export default {
  name: "TreeNode",
  components: {
    AccordionRowsRow,
  },
  props: {
    entities: {
      type: Object,
      default: () => null,
    },
    arrayItem: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => null,
    },
    spacing: {
      type: Number,
      default: 24,
    },
    isPending: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showChildren: false,
    };
  },
  setup(props) {
    const flowDetailsStore = useFlowDetails();
    const router = useRouter();
    const toast = useToast();

    const nodeMargin = computed(() => {
      return {
        "margin-left": `${props.spacing}px`,
      };
    });

    const hasChildren = computed(() => {
      const { children } = props.arrayItem;
      const { buffer_id } = props.arrayItem;
      const { label } = props.row;

      // if it is a part group, check if it has a buffer_id
      // if it has a buffer_id, return false and do not show children
      if (label.trim().includes("Part Group") && buffer_id) return false;

      // otherwise show children if there are children to show
      return children && children.length > 0;
    });

    const flowDetailsParams = computedRef(() => {
      return {
        query: {
          regarding_object_id: props?.arrayItem?.buffer_id,
          type: "DestinationBuffer",
        },
      };
    });

    const flowDetailsQueryWhen = computedRef(
      () => !!get(flowDetailsParams?.value, "query.regarding_object_id")
    );

    const { items: flowDetailsResults } = useFind({
      model: flowDetailsStore.Model,
      params: flowDetailsParams,
      queryWhen: flowDetailsQueryWhen,
    });

    const hasFlowResults = computed(() => {
      return flowDetailsResults.value.length > 0;
    });

    function routeToRelatedBufferFlow() {
      if (flowDetailsResults?.value.length === 1) {
        router.push({
          name: "FlowViewer",
          params: { flowId: flowDetailsResults.value[0].flow_id },
        });
      } else {
        toast.error(
          flowDetailsResults?.value.length + " flows found, cannot route"
        );
      }
    }

    return {
      nodeMargin,
      hasChildren,
      get,
      routeToRelatedBufferFlow,
      hasFlowResults,
    };
  },
};
</script>
<style scoped>
.child-icon {
  margin-left: -16px;
  margin-top: 4px;
  position: absolute;
}
</style>
