<template>
  <v-container>
    <b v-if="row.showLabel">{{ row.label }}</b>
    <v-card outlined>
      <template v-for="(entityRow, i) in get(entities, row.arrayPath)">
        <v-sheet :key="entityRow.id" class="pt-4">
          <v-container class="px-0">
            <AccordionRowsRow
              :row="row"
              :entities="entities"
              :arrayItem="entityRow"
              :isPending="isPending"
            />
          </v-container>
        </v-sheet>
        <v-divider
          :key="`${entityRow.id}-div`"
          v-if="arrayLength !== i + 1"
        ></v-divider>
      </template>
    </v-card>
  </v-container>
</template>

<script>
import { computed } from "vue-demi";
import { get } from "lodash-es";
import AccordionRowsRow from "./AccordionRowsRow.vue";

export default {
  name: "AccordionSectionRowArray",
  components: {
    AccordionRowsRow,
  },
  props: {
    entities: {
      type: Object,
      default: () => null,
    },
    row: {
      type: Object,
      default: () => null,
    },
    isPending: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    // console.log("accordionRowsArray", props);
    const arrayLength = computed(() => {
      return get(props.entities, props.row.arrayPath).length;
    });

    return {
      get,
      arrayLength,
    };
  },
};
</script>
