var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"container-grid"},[(_vm.row)?_c('v-row',{staticClass:"accordion-row-row"},[(_vm.row.showLabel && _vm.row.rowType !== 'array')?_c('v-col',{attrs:{"cols":"12"}},[_c('b',[_vm._v(_vm._s(_vm.row.label))])]):_vm._e(),_vm._l((_vm.row.fields),function(field){
var _obj;
return [_c('v-col',_vm._b({key:field.id},'v-col',field.colAttributes,false),[_c(field.component,_vm._b({tag:"v-component",attrs:{"disabled":field.attributes.disabled || true,"loading":_vm.entityLoading(field.entity),"dense":""}},'v-component',Object.assign({}, field.attributes,
            {label:
              typeof field.attributes.label === 'function'
                ? field.attributes.label(field, _vm.entities, _vm.arrayItem)
                : field.attributes.label},
            ( _obj = {}, _obj[field.valueAttribute] = _vm.entityGet(field.entity, field.property), _obj ),
            (field.includeEntities ? { entities: _vm.entities } : {})),false))],1)]})],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }