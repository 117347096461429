<template>
  <LoadingContainer :loading="!isReady" fluid containerClass="pa-0">
    <v-form class="flow-editor-accordion-form" v-if="isReady">
      <v-container fluid class="pa-0 pt-5 pb-3">
        <v-component
          v-for="renderRow in renderRows"
          :key="renderRow.id"
          :is="renderRow.component"
          :entities="entities"
          :row="renderRow"
          :isPending="isPending"
          v-bind="renderRow.vBind"
        ></v-component>
      </v-container>
    </v-form>
  </LoadingContainer>
</template>

<script>
import { computed } from "vue-demi";
import AccordionSectionRow from "@/components/workflow-accordion/AccordionRowsRow.vue";
import AccordionSectionRowArray from "@/components/workflow-accordion/AccordionRowsArray.vue";
import LoadingContainer from "@/components/LoadingContainer.vue";
import AccordionSectionTree from "@/components/workflow-accordion/AccordionRowsTree.vue";
export {
  entityRow,
  entityRowField,
} from "@/components/workflow-accordion/AccordionRowsRow.vue";

const rowTypeComponents = {
  fields: AccordionSectionRow,
  array: AccordionSectionRowArray,
  tree: AccordionSectionTree,
};

export default {
  name: "AccordionSection",
  props: {
    entities: {
      type: Object,
      default: () => null,
    },
    fields: {
      type: Array,
      default: () => [],
    },
    isPending: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LoadingContainer,
  },
  setup(props) {
    // console.log("AccordionRows", props);
    const renderRows = computed(() => {
      return props.fields?.map((row) => {
        let component;
        if (row.component && !!row.rowType) {
          component = row.component;
        } else {
          component = rowTypeComponents[row.rowType ?? "fields"];
        }
        return {
          fields: [],
          component: component,
          vBind: row.vBind ?? {},
          ...row,
        };
      });
    });

    const isReady = computed(() => true);

    return {
      renderRows,
      isReady,
      rowTypeComponents,
    };
  },
};
</script>
