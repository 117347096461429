<template>
  <div>
    <TreeNode
      v-for="entityRow in get(entities, row.arrayPath)"
      :key="entityRow.id"
      :row="row"
      :entities="entities"
      :arrayItem="entityRow"
      :isPending="isPending"
    />
  </div>
</template>

<script>
import { get } from "lodash-es";
import TreeNode from "./AccordionTreeNode.vue";

export default {
  name: "AccordionSectionTree",
  components: {
    TreeNode,
  },
  props: {
    entities: {
      type: Object,
      default: () => null,
    },
    row: {
      type: Object,
      default: () => null,
    },
    isPending: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      get,
    };
  },
};
</script>
