<template>
  <v-container class="container-grid">
    <v-row v-if="row" class="accordion-row-row">
      <v-col cols="12" v-if="row.showLabel && row.rowType !== 'array'">
        <b>{{ row.label }}</b>
      </v-col>
      <template v-for="field in row.fields">
        <v-col :key="field.id" v-bind="field.colAttributes">
          <v-component
            :is="field.component"
            v-bind="{
              ...field.attributes,
              label:
                typeof field.attributes.label === 'function'
                  ? field.attributes.label(field, entities, arrayItem)
                  : field.attributes.label,
              [field.valueAttribute]: entityGet(field.entity, field.property),
              ...(field.includeEntities ? { entities: entities } : {}),
            }"
            :disabled="field.attributes.disabled || true"
            :loading="entityLoading(field.entity)"
            dense
          >
          </v-component>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import { computed } from "vue-demi";
import { defaults, get, has, omit } from "lodash-es";
import LoadingContainer from "@/components/LoadingContainer.vue";
import { hasIn } from "lodash-es";

// Define component types
const components = {
  text: () => import(`vuetify/lib/components/VTextField`),
  textarea: () => import(`vuetify/lib/components/VTextarea`),
  checkbox: () =>
    import(`vuetify/lib/components/VCheckbox`).then(
      (module) => module.VCheckbox
    ),
  select: () => import(`vuetify/lib/components/VSelect`),
  documents: () =>
    import(`@/components/workflow-accordion/AccordionDocuments.vue`),
  averageWeeklyDemand: () =>
    import(`@/components/workflow-accordion/AccordionAverageWeeklyDemand.vue`),
};

const getAttributes = (type, overrides) => {
  const defaults = {
    textarea: {
      rows: 1,
      "auto-grow": true,
    },
  };
  if (has(defaults, type)) return { ...defaults[type], ...overrides };
  return overrides;
};

export const entityRow = (label, fields = [], additionalData = {}) => {
  if (additionalData.rowType === "array") {
    if (!additionalData.arrayPath)
      throw new Error("An array row must have an arrayPath property");
  }
  return {
    id: `${label}-${fields.length}`,
    label,
    fields,
    rowType: "fields",
    ...additionalData,
  };
};

export const entityRowField = (entity, property, attributes = {}) => {
  let valueAttribute = attributes.valueAttribute ?? "value";
  if (attributes.type === "checkbox") valueAttribute = "inputValue";
  // Define the component to use
  const component = components[attributes.type ?? "text"];
  // Define the default column attributes
  const colAttributeDefaults = {
    default: {
      cols: 12,
      sm: 4,
    },
    documents: {
      cols: 12,
      sm: 12,
    },
    averageWeeklyDemand: {
      cols: 12,
      sm: 12,
    },
  };
  return {
    // Create an ID for this field
    id: `${entity}.${property ?? "value"}`,
    entity,
    property,
    component: component,
    includeEntities: attributes.includeEntities ?? false,
    valueAttribute,
    attributes: getAttributes(attributes.type, {
      label: property,
      ...omit(attributes, ["type", "colAttributes", "includeEntities"]),
      // Force placeholder, for persistent label positioning
      placeholder: "",
      ["persistent-placeholder"]: true,
    }),
    colAttributes: defaults(
      attributes.colAttributes ?? {},
      colAttributeDefaults[attributes.type] ?? colAttributeDefaults.default
    ),
  };
};

export default {
  name: "AccordionSectionRow",
  props: {
    entities: {
      type: Object,
      default: () => null,
    },
    arrayItem: {
      type: Object,
      default: () => ({}),
    },
    row: {
      type: Object,
      default: () => null,
    },
    isPending: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    LoadingContainer,
  },
  setup(props) {
    const isReady = computed(() => true);

    const getEntityAccessor = (entity) => {
      if (typeof entity === "function") return entity(props);
      if (entity === "arrayItem") return props.arrayItem;
      return props.entities[entity];
    };

    const entityHas = (entity, property) =>
      has(getEntityAccessor(entity), property);

    const entityGet = (entity, property) => {
      if (property === null) return getEntityAccessor(entity);
      return get(getEntityAccessor(entity), property);
    };

    const entityLoading = (entity) => {
      // If the entity is not a string, assume it is loaded
      if (typeof entity !== "string") return false;
      if (props.isPending[entity] === undefined)
        console.warn(
          "Unhandled entityLoading",
          entity,
          props.isPending[entity]
        );
      // If an isPending property is not defined, assume the entity is loaded
      if (!hasIn(props.isPending, entity)) return false;
      // Otherwise, return the isPending value for the entity
      return get(props.isPending, entity, false);
    };

    return {
      components,
      isReady,
      entityHas,
      entityGet,
      entityLoading,
      get,
    };
  },
};
</script>

<style lang="scss">
.accordion-row-row {
  & > [class*="col-"] {
    padding: 0 12px;
  }
}
</style>
